import React from 'react';
import { pagesList } from 'proxyModule/pagesList';
import { Navigate } from 'react-router-dom';
import { LocalStorage } from 'libModule/LocalStorage';
import { checkIsFieldhouseAuth } from 'libModule/checkIsFieldhouseAuth';
import Preloader from 'uiModule/Preloader';

const LogInAsPageLazy = React.lazy(() =>
  import('./pages/LoginAsPage/LoginAsPage')
);

const getDefaultRouts = (userToken) => {
  // during stripe user registration, don't redirect to graphics page
  if (LocalStorage.get('isStripeUser')) return [];
  if (userToken) {
    return [
      { path: '/', element: <Navigate to="/graphics" /> },
      {
        path: '/login_as',
        element: (
          <React.Suspense fallback={<Preloader active={true} />}>
            <LogInAsPageLazy />
          </React.Suspense>
        ),
      },
      { path: '*', element: <Navigate to="/graphics" /> },
    ];
  } else {
    return [
      { path: '/', element: <Navigate to="/sign_up" /> },
      {
        path: '/login_as',
        element: (
          <React.Suspense fallback={<Preloader />}>
            <LogInAsPageLazy />
          </React.Suspense>
        ),
      },
      { path: '*', element: <Navigate to="/sign_up" /> },
    ];
  }
};

const formRoutes = (userToken) => {
  const destination = userToken ? 'private' : 'public';
  return Object.keys(pagesList[destination]).map((key) => {
    const routObject = pagesList[destination][key];
    let Element = routObject.element;

    const routeElement = <Element theme={window.theme || {}} />;
    return { path: routObject.path, element: routeElement };
  });
};

// routes which available under some conditions and block other routes to go. works for private and public layouts
const getRestrictedRoutes = (meta, user) => {
  if (meta.onboarding) {
    return [
      {
        path: '/onboarding',
        element: <pagesList.restricted.Onboarding />,
      },
      {
        path: '*',
        element: <Navigate to="/onboarding" />,
      },
    ];
  }
  if (user.need_update_organization_type && LocalStorage.get('isStripeUser')) {
    return [
      {
        path: '/sign_up_stripe_user',
        element: <pagesList.restricted.Onboarding />,
      },
      { path: '*', element: <Navigate to="/sign_up_stripe_user" /> },
    ];
  }

  if (checkIsFieldhouseAuth()) {
    return [
      { path: '/login', element: <pagesList.public.LoginPage.element /> },
    ];
  }

  // if Basic plan already have 2 users, show to other users logout Notification modal
  if ((!user.workspace && user.id) || user.can_use_site === false) {
    return [
      {
        path: '/settings/account',
        element: <pagesList.private.AccountPage.element />,
      },
      { path: '*', element: <Navigate to="/settings/account" /> },
    ];
  }

  return null;
};

const routes = (userToken, user, meta) => {
  const restrictedRoutes = getRestrictedRoutes(meta, user);
  if (restrictedRoutes) {
    return restrictedRoutes;
  }

  return [...getDefaultRouts(userToken), ...formRoutes(userToken)];
};

export { routes };
