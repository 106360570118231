import { useCallback } from 'react';
import { useSmartlook } from 'libModule/useSmartlook';

const NEW_USER_MINS = 15; // number of minutes during which a user can be considered as new

export const useSmartlookForNewUser = () => {
  const smartlook = useSmartlook();

  return useCallback(
    (visitor) => {
      const now = new Date();

      const firstVisitDate =
        new Date(visitor.invited_activation_date).getTime() ||
        new Date(visitor.created_date).getTime();

      const diffInMs = now - firstVisitDate;
      const diffInMins = diffInMs / (1000 * 60);

      if (diffInMins > NEW_USER_MINS) {
        console.log('old user');
        return;
      }

      if (smartlook.isInitialized()) {
        smartlook.resume();
      } else {
        smartlook.init();
        smartlook.identify(visitor);
      }

      smartlook.enablePriorityRecording();

      const pauseIn = NEW_USER_MINS * 60 * 1000 - diffInMs;
      setTimeout(smartlook.pause, pauseIn);
    },
    [smartlook]
  );
};
