import { LocalStorage } from 'libModule/LocalStorage';

const setCookie = (key, value) => {
  document.cookie = `${key}=${value}; path=/`;
};

export const storeHubspotCookies = () => {
  if (!window.location.search.includes('__hstc')) {
    return;
  }

  const { search } = window.location;
  const newHstc = new URLSearchParams(search).get('__hstc');
  const newHubspotutk = newHstc.split('.')[1];

  // for Hubspot purposes as it requires these params in cookies
  setCookie('__hstc', newHstc);
  setCookie('hubspotutk', newHubspotutk);

  // for our purposes
  LocalStorage.set('hubspotutk', newHubspotutk);
};
